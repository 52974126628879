// src/App.js
import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Fireworks } from 'fireworks-js';
import ReactHowler from 'react-howler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChampagneGlasses } from '@fortawesome/free-solid-svg-icons';
import celebratoryMusic from './assets/music/celebratory-music.mp3';
import './App.css';
import DonateButton from './DonateButton'; // Importar el componente de donación
import { useTranslation } from 'react-i18next'; // Importar el hook useTranslation
import { FaFlagUsa, FaFlag } from 'react-icons/fa'; // Importar iconos de banderas

function App() {
  const { t, i18n } = useTranslation(); // Inicializar el hook
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  let nombre = searchParams.get('n');

  const [isShared, setIsShared] = useState(false);
  const [countdown, setCountdown] = useState('');
  const [isNewYear, setIsNewYear] = useState(false);
  const [theme, setTheme] = useState('dark');
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [name, setName] = useState(''); // Estado para el nombre
  const fireworksContainer = useRef(null);
  
  const isTesting = false; // Cambia a true para probar que ya es Año Nuevo
  const nextYear = new Date().getFullYear() + 1;

  // Función para cambiar el idioma
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Mensaje personalizado para WhatsApp
  const mensajePersonalizado = isNewYear
    ? encodeURIComponent(t('shareMessageNewYear', { year: nextYear, url: window.location.href }))
    : encodeURIComponent(t('shareMessageGeneral', { name: nombre ? nombre : (t('greetingHello') + ' ' + t('greetingMessage')), url: window.location.href }));
  const whatsappURL = `https://wa.me/?text=${mensajePersonalizado}`;

  // Función para manejar el cambio en el input y capitalizar la primera letra
  const handleNameChange = (e) => {
    const input = e.target.value;
    if (input.length === 0) {
      setName('');
    } else {
      const capitalized = input.charAt(0).toUpperCase() + input.slice(1);
      setName(capitalized);
    }
  };

  const handleShare = () => {
    if (name.trim() !== '') {
      navigate(`/?n=${encodeURIComponent(name.trim())}`);
      setIsShared(true);
    } else {
      alert(t('pleaseEnterName')); // Mensaje traducido
    }
  };

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const toggleMusic = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  useEffect(() => {
    let intervalId;

    const calculateCountdown = () => {
      const now = isTesting
        ? new Date(`January 2, ${nextYear} 00:00:00`)
        : new Date();
      const newYear = new Date(`January 1, ${nextYear} 00:00:00`);
      const timeLeft = newYear - now;

      if (timeLeft <= 0) {
        setIsNewYear(true);
        setCountdown(t('newYearGreeting', { year: nextYear }));
        clearInterval(intervalId);
        return;
      }

      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

      setCountdown(
        t('countdown', { days, hours, minutes, seconds })
      );
    };

    calculateCountdown();
    intervalId = setInterval(calculateCountdown, 1000);

    if (fireworksContainer.current) {
      const fireworks = new Fireworks(fireworksContainer.current.firstChild, {
        autoresize: true,
        opacity: 0.8,
        acceleration: 1,
        friction: 0.95,
        gravity: 1.8,
        particles: 80,
        traceLength: 5,
        traceSpeed: 15,
        explosion: 5,
        intensity: 10,
        flickering: 70,
        lineStyle: 'round',
        colors: ['#FFD700', '#B8860B', '#DAA520'],
        delay: { min: 20, max: 40 },
        rocketsPoint: { min: 0, max: 100 },
        lineWidth: {
          explosion: { min: 2, max: 4 },
          trace: { min: 1, max: 3 },
        },
        brightness: { min: 60, max: 100 },
        decay: { min: 0.02, max: 0.04 },
        mouse: { click: false, move: false, max: 2 },
      });

      fireworks.start();

      const timer = setTimeout(() => {
        fireworks.stop();
      }, 200000);

      return () => {
        clearTimeout(timer);
        fireworks.stop();
        clearInterval(intervalId);
      };
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [nextYear, isTesting, t]);

  return (
    <div
      className={`App ${theme} flex flex-col items-center justify-center min-h-screen relative`}
      style={{ overflow: 'hidden' }}
    >
      {/* Reproductor de Música */}
      <ReactHowler
        src={celebratoryMusic}
        playing={isMusicPlaying}
        loop={true}
        volume={0.5}
      />

      {/* Contenedor para la Música y el Selector de Idioma */}
      <div className="absolute top-2 left-2 z-50 flex flex-col items-start space-y-1 sm:top-4 sm:left-4 sm:space-y-2">
        {/* Contenedor Horizontal para Música y Tema */}
        <div className="flex space-x-1 sm:space-x-2">
          {/* Botón de Control de Música */}
          <button
            className="px-2 py-1 text-xs sm:px-3 sm:py-2 sm:text-sm rounded transition duration-300 transform hover:scale-110 music-toggle"
            style={{
              backgroundColor: 'var(--primary-color)',
              color: 'var(--background-color)',
            }}
            onClick={toggleMusic}
          >
            {isMusicPlaying ? t('pauseMusic') : t('playMusic')}
          </button>

          {/* Botón "Cambiar a tema" */}
          <button
            className="px-2 py-1 text-xs sm:px-3 sm:py-2 sm:text-sm rounded transition duration-300 transform hover:scale-110 theme-toggle"
            style={{
              backgroundColor: 'var(--primary-color)',
              color: 'var(--background-color)',
            }}
            onClick={toggleTheme}
          >
            {t('changeTheme', { theme: theme === 'dark' ? t('light') : t('dark') })}
          </button>
        </div>

        {/* Selector de Idioma */}
        <div className="flex space-x-1 sm:space-x-2">
          <button
            onClick={() => changeLanguage('en')}
            className={`flex items-center px-1 py-0.5 text-xs sm:px-2 sm:py-1 sm:text-sm rounded mr-1 sm:mr-2 ${i18n.language === 'en' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            <FaFlagUsa className="mr-1" /> EN
          </button>
          <button
            onClick={() => changeLanguage('es')}
            className={`flex items-center px-1 py-0.5 text-xs sm:px-2 sm:py-1 sm:text-sm rounded ${i18n.language === 'es' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            <FaFlag className="mr-1" /> ES
          </button>
        </div>
      </div>

      {/* Fuegos Artificiales */}
      <div
        ref={fireworksContainer}
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          zIndex: 10,
        }}
      >
        <canvas style={{ width: '100%', height: '100%' }} />
      </div>

      {/* Año Grande */}
      <div className="absolute inset-0 flex items-center justify-center">
        <h1
          className="text-9xl sm:text-9xl md:text-[15rem] font-extrabold opacity-30 font-cursive animate-shine text-shadow"
          style={{ color: 'var(--primary-color)' }}
        >
          {nextYear}
        </h1>
      </div>

      {/* Header con Mensaje */}
      <header className="App-header text-center mt-8 relative z-10 flex flex-col items-center px-4 sm:px-0">
        {isNewYear ? (
          <p className="font-cursive text-3xl sm:text-4xl md:text-6xl mb-4 animate-fadeIn">
            {t('newYearGreeting', { year: nextYear })}
          </p>
        ) : (
          <>
            {nombre ? (
              <>
                <p className="font-cursive text-3xl sm:text-4xl md:text-6xl mb-2 animate-fadeIn">
                  {nombre}
                </p>
                <p className="font-cursive text-2xl sm:text-3xl md:text-4xl mb-2 animate-fadeIn">
                  {t('greetingMessageWithName')}
                </p>
                <p className="font-cursive text-2xl sm:text-3xl md:text-4xl mb-4 animate-fadeIn">
                  {t('greetingMessageWithName2', { year: nextYear })}
                </p>
              </>
            ) : (
              <>
                <p className="font-cursive text-3xl sm:text-4xl md:text-6xl mb-2 animate-fadeIn">
                  {t('greetingHello')}
                </p>
                <p className="font-cursive text-2xl sm:text-3xl md:text-4xl mb-2 animate-fadeIn">
                  {t('greetingMessage')}
                </p>
                <p className="font-cursive text-2xl sm:text-3xl md:text-4xl mb-4 animate-fadeIn">
                  {t('greetingMessage2', { year: nextYear })}
                </p>
              </>
            )}
            {!isNewYear && (
              <p className="countdown text-lg sm:text-xl md:text-2xl mb-4">{countdown}</p>
            )}
          </>
        )}
      </header>

      {/* Icono Festivo Centralizado */}
      <div className="flex justify-center mt-4 animate-bounce">
        <FontAwesomeIcon icon={faChampagneGlasses} size="4x" color="#FFD700" />
      </div>

      {/* Sección de Compartir */}
      <div className="Share-section mt-8 flex flex-col items-center relative z-20 w-full sm:w-auto px-4">
        {isNewYear ? (
          // Cuando es Año Nuevo, muestra el botón de compartir y el de donación
          <>
            <button
              onClick={() => window.open(whatsappURL, '_blank')}
              className="WhatsappShareButton px-4 py-2 rounded mt-4 hover:bg-green-600 transition duration-300 w-full sm:w-80"
              style={{
                backgroundColor: '#25D366',
                color: '#fff',
              }}
            >
              {t('shareWhatsApp')}
            </button>
            {/* Botón de Donación debajo del botón de Compartir */}
            <DonateButton className="mt-4 w-full sm:w-80" />
            {/* Footer debajo del botón de Donación */}
            <footer className="mt-4 text-center">
              <p className="text-sm text-gray-500">
                {t('footerText')}
                <a
                  href="mailto:castrodeveloper12@gmail.com"
                  className="text-blue-500 underline"
                >
                  {t('footerHere')}
                </a>.
              </p>
            </footer>
          </>
        ) : !isShared ? (
          // Antes de Año Nuevo, muestra el campo de texto y el botón "Ver"
          <>
            <input
              id="shareText"
              type="text"
              placeholder={t('inputPlaceholder')}
              value={name}
              onChange={handleNameChange}
              className="px-4 py-2 border rounded mb-4 w-full sm:w-80"
              style={{
                color: 'var(--text-color)',
                borderColor: 'var(--primary-color)',
                backgroundColor: 'transparent',
              }}
            />
            <button
              onClick={handleShare}
              className="px-4 py-2 rounded hover:bg-secondary transition duration-300 w-full sm:w-80"
              style={{
                backgroundColor: 'var(--primary-color)',
                color: 'var(--background-color)',
              }}
            >
              {t('viewButton')}
            </button>
            {/* Botón de Donación debajo del botón "Ver" */}
            <DonateButton className="mt-4 w-full sm:w-80" />
            {/* Footer debajo del botón de Donación */}
            <footer className="mt-4 text-center">
              <p className="text-sm text-gray-500">
                {t('footerText')}
                <a
                  href="mailto:castrodeveloper12@gmail.com"
                  className="text-blue-500 underline"
                >
                  {t('footerHere')}
                </a>.
              </p>
            </footer>
          </>
        ) : (
          // Después de ingresar el nombre, muestra el botón de compartir
          <>
            <button
              onClick={() => window.open(whatsappURL, '_blank')}
              className="WhatsappShareButton px-4 py-2 rounded mt-4 hover:bg-green-600 transition duration-300 w-full sm:w-80"
              style={{
                backgroundColor: '#25D366',
                color: '#fff',
              }}
            >
              {t('shareWhatsApp')}
            </button>
            {/* Botón de Donación debajo del botón de Compartir */}
            <DonateButton className="mt-4 w-full sm:w-80" />
            {/* Footer debajo del botón de Donación */}
            <footer className="mt-4 text-center">
              <p className="text-sm text-gray-500">
                {t('footerText')}
                <a
                  href="mailto:castrodeveloper12@gmail.com"
                  className="text-blue-500 underline"
                >
                  {t('footerHere')}
                </a>.
              </p>
            </footer>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
