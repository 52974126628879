// src/DonateButton.js
import React from 'react';
import { FaCoffee } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const DonateButton = ({ className }) => {
  const { t } = useTranslation(); // Obtener el hook useTranslation

  return (
    <a
      href="https://www.buymeacoffee.com/castrodeveloper" // Reemplaza con tu usuario real
      target="_blank"
      rel="noopener noreferrer"
      className={className} // Aplicar la clase pasada
    >
      <button className="px-4 py-2 rounded bg-yellow-500 text-white hover:bg-yellow-600 transition duration-300 flex items-center">
        <FaCoffee className="mr-2" /> {t('donateButton')}
      </button>
    </a>
  );
};

export default DonateButton;
